import styles from './preview.module.scss';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info-icon.svg';
// import {ReactComponent as EyeIcon} from '../../../../assets/svg/eye.svg';
import {formatPrice, valueExists} from '../../../../utils/utils';
import {useEffect, useMemo, useState} from 'react';
import Loading from '../../../status/loading';
import ErrorModalScreen from '../../shared/common/ErrorModalScreen';
import clsx from 'clsx';

const Preview = ({
	provider,
	targetInfo,
	availableAccounts,
	fiatAmount,
	fiatCurrency,
	setExchangeAccountId,
	setNetwork,
	setValueInfo,
	handleNextStep,
	canProceed,
	loading,
	error
}) => {
	const paymentOptions = useMemo(() => {
		return Object.entries(availableAccounts).flatMap(([accountId, value]) => {
			const networkOptions = value.network_options ?? [];
			return networkOptions.map(networkOption => ({
				accountId,
				network: networkOption?.name,
				currency: value?.currency,
				currencyName: value?.currency_name,
				currencySymbol: value?.currency_symbol,
				minAmount: networkOption?.min_withdrawal_amount ?? 0,
				minFee: networkOption?.min_withdrawal_fee,
				value: value?.value,
				exchangeRate: value?.exchangeRate,
				isDisabled: (value?.value ?? 0) < (networkOption?.min_withdrawal_amount ?? 0)
			}));
		});
	}, [availableAccounts]);

	const [selectedPaymentOption, setSelectedPaymentOption] = useState();

	const exchangeRate = useMemo(
		() => (selectedPaymentOption?.value ?? 0) === 0 ? 0 : fiatAmount / selectedPaymentOption.value,
		[fiatAmount, selectedPaymentOption]
	);

	useEffect(() => {
		if (selectedPaymentOption === undefined && paymentOptions.length > 0) {
			setSelectedPaymentOption(paymentOptions[0]);
		}
	}, [paymentOptions]);

	const handleExchangeAccountChange = (e) => {
		const index = e.target.value;
		setSelectedPaymentOption(paymentOptions[index]);
	};

	const handleSubmit = () => {
		setValueInfo({
			value: selectedPaymentOption.value,
			currency: selectedPaymentOption.currencyName,
			converted_value: fiatAmount,
			converted_currency: fiatCurrency,
		});
		setExchangeAccountId(selectedPaymentOption.accountId);
		setNetwork(selectedPaymentOption.network);
		handleNextStep();
	};

	if (loading) {
		return <div className={styles.loadingContainer}>
			<Loading/>
		</div>;
	}

	if (valueExists(error)) {
		return <ErrorModalScreen message={error}/>;
	}

	return <div className={styles.container}>
		<div className={styles.topSection}>
			<div className={styles.heading}>
				Preview
			</div>
			<div className={styles.summaryContainer}>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
						From
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={provider?.icon} alt={provider?.description}/>
						</div>
						<div>
							{provider?.description}
						</div>
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
						To
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={targetInfo?.icon} alt={targetInfo?.description}/>
						</div>
						<div>
							{targetInfo?.description}
						</div>
						{/*<div>*/}
						{/*	({shortenString(targetInfo?.address, 12)})*/}
						{/*</div>*/}
						{/*<div className={styles.purpleIcon}>*/}
						{/*	<EyeIcon/>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className={styles.divider}></div>

				{/*<div className={styles.summaryLineContainer}>*/}
				{/*	<div className={styles.rowTitle}>*/}
				{/*		Network*/}
				{/*	</div>*/}
				{/*	<div>*/}
				{/*		{network}*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className={styles.divider}></div>*/}

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
						Funding method
					</div>
					<div className={styles.radioOptions}>
						{paymentOptions.map((paymentOption, index) =>
							<div className={styles.optionContainer} key={`${paymentOption.accountId}/${paymentOptions.network}`}>
								<div className={styles.radioItemContainer}>
									<input
										type='radio'
										name='balance-radios'
										value={index}
										checked={
											selectedPaymentOption?.accountId === paymentOption.accountId &&
											selectedPaymentOption?.network === paymentOption.network
										}
										onChange={handleExchangeAccountChange}
										disabled={paymentOption.isDisabled}
									/>
									<div className={clsx(paymentOption.isDisabled && styles.disabled)}>
										{paymentOption.currency} ({paymentOption.network} network)
									</div>
								</div>
								{paymentOption.isDisabled && <div className={styles.minAmountWarning}>
									<div className={styles.icon}>
										<InfoIcon />
									</div>
									<div className={styles.minAmountText}>
										Min deposit amount: {paymentOption.minAmount} {paymentOption.currency}
									</div>
								</div>}
							</div>
						)}
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.totalLineContainer}>
					<div>
						Total
					</div>
					<div>
						{(selectedPaymentOption?.value ?? 0).toFixed(6)} {selectedPaymentOption?.currencyName} ≈ {formatPrice(fiatAmount, fiatCurrency, 2)}
					</div>
				</div>
			</div>
		</div>

		<div className={styles.bottomSection}>
			<div className={styles.feesHeading}>
				All fees included
			</div>

			<div className={styles.feeInfoLineContainer}>
				<div>
					{(selectedPaymentOption?.value ?? 0).toFixed(6)} {selectedPaymentOption?.currencyName} @ {formatPrice(exchangeRate, fiatCurrency, 2)}
				</div>
				<div>
					{formatPrice(fiatAmount, fiatCurrency, 2)}
				</div>
			</div>


			<div className={styles.feeInfoLineContainer}>
				<div>
					Exchange withdrawal fee
				</div>
				{typeof(selectedPaymentOption?.minFee) === 'number' ?
					<div>
						{formatPrice(selectedPaymentOption.minFee * exchangeRate, fiatCurrency, 2)}
					</div> :
					<div>
						Set by {provider.name}
					</div>
				}
			</div>

			<div className={styles.feeInfoLineContainer}>
				{/*<div>*/}
				{/*	Estimated gas fee*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	{formatPrice(42.16, fiatCurrency, 2)}*/}
				{/*</div>*/}
			</div>
			<MainButton
				className='confirm-button'
				onClick={handleSubmit}
				label='Proceed'
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!canProceed}
			/>
		</div>
	</div>;
};

export default Preview;